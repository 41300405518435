import {Button, Typography} from '@material-ui/core'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {
	TemplateContainerComponent as TemplateContainer,
	CyclisHeaderComponent as CyclisHeader,
} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {AnimatedTextSvg, CyclisLogoSvg} from '@svg/index'

import './404.css'

const NotFoundPage: React.FC = () => {
	const {isAuthenticated} = useSelector((state: RootState) => state.auth)

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)

	const {t} = useTranslation(undefined, {useSuspense: false})

	const navigateBack = (): void => {
		history.back()
	}

	return (
		<TemplateContainer id="not-found-page">
			{isAuthenticated ? (
				<CyclisHeader customerName={`${firstName} ${lastName}`} />
			) : (
				<CyclisLogoSvg className="logo" color />
			)}

			<AnimatedTextSvg className="animatedTextSvg" text="404" fontSize={200} />
			<Typography variant="h3" className="pageNotFoundTitle">
				{t('PageNotFoundTitle')}
			</Typography>
			<Typography variant="body1" className="pageNotFoundSubtitle">
				{t('PageNotFoundSubtitle')}
			</Typography>
			<Button
				id="go-back"
				className="pageNotFoundGoBackButton"
				variant="contained"
				color="primary"
				onClick={navigateBack}
			>
				{t('PageNotFoundGoBackButton')}
			</Button>
		</TemplateContainer>
	)
}

export default NotFoundPage
