import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside CyclistProfile functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#edf2f8',
	},
	mainContentContainer: {
		marginTop: theme.spacing(60 / 8),
	},
	backToOverviewContainer: {
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center',
	},
	backToOverviewText: {
		fontFamily: 'Montserrat,sans-serif',
		fontSize: 16,
		fontWeight: 'bold',
		color: '#08080d',
		marginLeft: theme.spacing(13 / 8),
	},
	nameTitle: {
		fontSize: 36,
		fontWeight: 'bold',
		color: '#08080d',
		marginTop: theme.spacing(26 / 8),
	},
	cardsContainer: {
		marginTop: theme.spacing(40 / 8),
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	bicycleCard: {
		background: 'url(/images/cyclist-profile-bicycle-background.svg)',
		backgroundSize: 'cover',
		height: theme.spacing(209 / 8),
		borderRadius: theme.spacing(8 / 8),
		padding: theme.spacing(24 / 8),
		cursor: 'pointer',
	},
	verticalCardsContainerLeft: {
		width: '65%',
	},
	verticalCardsContainerRight: {
		width: '32%',
	},
	newInsuranceCard: {
		background: 'url(/images/cyclist-profile-new-insurance-background.svg)',
		backgroundSize: 'cover',
		height: theme.spacing(53 / 8),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: theme.spacing(8 / 8),
	},
	addMaintenanceCard: {
		background: 'url(/images/cyclist-profile-add-maintenance-background.svg)',
		backgroundSize: 'cover',
		marginTop: theme.spacing(30 / 8),
		height: theme.spacing(236 / 8),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: theme.spacing(8 / 8),
	},
	newQuotationCard: {
		background: 'url(/images/cyclist-profile-new-quotation-background.svg)',
		height: theme.spacing(80 / 8),
		marginTop: theme.spacing(30 / 8),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: theme.spacing(8 / 8),
	},
	soNumber: {
		fontSize: 16,
		fontWeight: 500,
		color: '#ffffff',
	},
	bicycleName: {
		fontSize: 24,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
		color: '#ffffff',
		marginBottom: theme.spacing(8 / 8),
	},
	seeMore: {
		fontSize: 12,
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
		color: '#ffffff',
	},
	newInsuranceCase: {
		fontWeight: 'bold',
		fontFamily: 'Montserrat,sans-serif',
		fontSize: 16,
		color: '#524fa2',
	},
	addIcon: {
		color: '#524fa2',
		marginRight: theme.spacing(7.5 / 8),
	},
	white: {
		color: '#ffffff',
	},
	seeMoreContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: theme.spacing(55 / 8),
	},
	arrowForward: {
		color: '#ffffff',
		marginLeft: theme.spacing(5 / 8),
	},
	link: {
		textDecoration: 'none',
	},
}))

export default useStyles
