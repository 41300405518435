import {Box, Grid, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import classNames from 'classnames'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import React, {useEffect, useState, useContext} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {
	TemplateContainerComponent,
	CyclisHeaderComponent as CyclisHeader,
} from '@components/index'
import NotFoundPage from '@pages/404'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {
	getCyclistProfile,
	resetCyclistProfileState,
} from '@templates/CyclistProfile/cyclist-profile-slice'
import {getCyclists, resetCyclistState} from '@templates/Home/cyclists-slice'
import {NO_GOOGLE_TRANSLATE} from '@utils/constants/util-constants'
import {LanguageContext} from '@utils/context'
import {toPrettyPrice} from '@utils/string-utils'
import {CommonProps} from '@utils/types'

import useStyles from './style.hook'

/**
 * CyclistProfile
 */
interface CyclistProfileProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

const CyclistProfile: React.FC<CyclistProfileProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()

	const dispatch = useDispatch()

	const {t} = useTranslation(undefined, {useSuspense: false})

	const [cyclistId, setCyclistId] = useState<number>()
	const [bicycleId, setBicycleId] = useState<number>()
	const [searchTerm, setSearchTerm] = useState<string>('')

	const {activeLanguage} = useContext(LanguageContext)

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const {cyclist, error} = useSelector(
		(state: RootState) => state.cyclistProfile
	)
	const cyclists = useSelector((state: RootState) => state.cyclists.cyclists)

	const goBack = (): void => {
		dispatch(
			navigate(`/${activeLanguage}/app/search-result?search=${searchTerm}`, {
				state: {
					cyclists,
				},
			})
		)
	}

	useEffect(() => {
		const {search} = location!
		const params = new URLSearchParams(search)
		const parsedCyclistId = parseInt(params.get('cyclist')!, 10)
		const parsedBicycleId = parseInt(params.get('bicycle')!, 10)
		const paramsSearchTerm = params.get('search')

		if (paramsSearchTerm) {
			setSearchTerm(paramsSearchTerm)
		}

		if (parsedCyclistId && parsedBicycleId) {
			setCyclistId(parsedCyclistId)
			setBicycleId(parsedBicycleId)
		}

		dispatch(getCyclists(paramsSearchTerm!))
	}, [location])

	useEffect(() => {
		if (cyclistId && bicycleId) {
			dispatch(getCyclistProfile(cyclistId, bicycleId))
		}
	}, [cyclistId, bicycleId])

	useEffect(() => {
		return (): void => {
			dispatch(resetCyclistProfileState())
			dispatch(resetCyclistState())
		}
	}, [])

	return error === 'StandardErrorNotFound' ? (
		<NotFoundPage />
	) : (
		<TemplateContainerComponent
			id={'cyclist-profile-container'}
			className={classes.root}
		>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			<Grid container spacing={8}>
				<Grid item xs={2} />
				<Grid item xs={8}>
					<Box className={classes.mainContentContainer}>
						<Box
							id={'cyclist=profile-go-back-container'}
							className={classes.backToOverviewContainer}
							onClick={goBack}
						>
							<ArrowBackIcon id={'cyclist-profile-go-back-icon'} />
							<Typography
								id={'cyclist-profile-go-back-text'}
								className={classes.backToOverviewText}
								variant={'body1'}
							>
								{t('CyclistProfileBackToOverview')}
							</Typography>
						</Box>
						{cyclist && (
							<Typography
								id={'cyclist-profile-name-header'}
								className={classNames(
									classes.nameTitle,
									(cyclist.cyclistFirstName || cyclist.cyclistLastName) &&
										NO_GOOGLE_TRANSLATE
								)}
								variant={'h2'}
							>{`${cyclist.cyclistFirstName || 'N/A'} ${
								cyclist.cyclistLastName || 'N/A'
							}`}</Typography>
						)}
						<Box className={classes.cardsContainer}>
							<Box className={classes.verticalCardsContainerLeft}>
								<Box
									id={'cyclist-profile-bicycle-box'}
									className={classes.bicycleCard}
								>
									{cyclist && (
										<Link
											to={`/${activeLanguage}/app/bicycles/detail?id=${cyclist.bicycleId}`}
											className={classes.link}
											onClick={(): void => {
												dispatch(
													navigate(
														`/${activeLanguage}/app/bicycles/detail?id=${cyclist.bicycleId}`
													)
												)
											}}
										>
											<Typography
												id={'cyclist-profile-so-number'}
												className={classNames(
													classes.soNumber,
													NO_GOOGLE_TRANSLATE
												)}
												variant={'body1'}
											>
												{cyclist.salesOrderNumber}
											</Typography>
											<Typography
												id={'cyclist-profile-bicycle-name'}
												className={classNames(
													classes.bicycleName,
													NO_GOOGLE_TRANSLATE
												)}
												variant={'body1'}
											>
												{`${cyclist.brand} - ${cyclist.model}`}
											</Typography>
											<Typography
												id={'cyclist-profile-maintenance-budget'}
												className={classes.soNumber}
												variant={'body1'}
											>
												{t('CyclistProfileCurrentMaintenanceBudget')}:{' '}
												{toPrettyPrice(
													Number(cyclist.availableMaintenanceBudget || 0)
												)}
											</Typography>
											<Box
												id={'cyclist-profile-see-more-box'}
												className={classes.seeMoreContainer}
											>
												<Typography
													id={'cyclist-profile-see-more-text'}
													className={classes.seeMore}
												>
													{t('CyclistProfileSeeMore')}
												</Typography>
												<ArrowForwardIcon
													id={'cyclist-profile-see-more-icon'}
													className={classes.arrowForward}
												/>
											</Box>
										</Link>
									)}
								</Box>
								<Link
									to={`/${activeLanguage}/app/quotations/new?cyclist=${cyclistId}&bicycle=${bicycleId}`}
									className={classes.link}
								>
									<Box
										id={'cyclist-profile-new-quotation-box'}
										className={classes.newQuotationCard}
									>
										<AddIcon
											id={'cyclist-profile-new-quotation-icon'}
											className={classes.addIcon}
										/>
										<Typography
											id={'cyclist-profile-new-quotation-text'}
											className={classes.newInsuranceCase}
											variant={'body1'}
										>
											{t('CyclistProfileNewQuotation')}
										</Typography>
									</Box>
								</Link>
							</Box>
							<Box className={classes.verticalCardsContainerRight}>
								<Box
									id={'cyclist-profile-new-insurance-box'}
									className={classes.newInsuranceCard}
									onClick={(): void => {
										dispatch(
											navigate(
												`/${activeLanguage}/app/bicycles/damages/new?id=${bicycleId}`
											)
										)
									}}
								>
									<AddIcon
										id={'cyclist-profile-new-insurance-icon'}
										className={classes.addIcon}
									/>
									<Typography
										id={'cyclist-profile-new-insurance-text'}
										className={classes.newInsuranceCase}
										variant={'body1'}
									>
										{t('CyclistProfileNewInsuranceCase')}
									</Typography>
								</Box>
								<Box
									id={'cyclist-profile-add-maintenance-box'}
									className={classes.addMaintenanceCard}
									onClick={(): void => {
										dispatch(
											navigate(
												`/${activeLanguage}/app/bicycles/detail?id=${bicycleId}`
											)
										)
									}}
								>
									<AddIcon
										id={'cyclist-profile-add-maintenance-icon'}
										className={classNames(classes.addIcon, classes.white)}
									/>
									<Typography
										id={'cyclist-profile-add-maintenance-text'}
										className={classNames(
											classes.newInsuranceCase,
											classes.white
										)}
										variant={'body1'}
									>
										{t('CyclistProfileAddMaintenance')}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={2} />
			</Grid>
		</TemplateContainerComponent>
	)
}

CyclistProfile.propTypes = {
	location: PropTypes.any,
}

export default CyclistProfile
